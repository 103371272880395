'use client';
import { useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { useScrollToCurrentSelection } from 'customHooks/useScrollToCurrentSelection';
import type { PropsWithClassName } from 'types/react-props';
import type { VideoProps } from 'types/videoTypes';
import { formatSecondsToMinutes } from 'utils/time';
import { getVideoDuration } from 'utils/videoPlayer';
import { Title } from '../TitleNew';
import type { TitleLink } from '../TitleNew/Title';
import VideoThumbnail from '../VideoThumbnail/VideoThumbnail';
import NavButtons from './components/NavButtons';
import styles from './VideoSwiper.module.scss';

type Props = PropsWithClassName<{
  readonly videoList: Array<VideoProps>;
  readonly currentVideoIndex?: number;
  readonly onClick?: (currentVideoIndex: number) => void;
  readonly title?: string;
  readonly titleLink?: TitleLink;
  readonly isAdPlaying?: boolean;
  readonly totalAdClips?: number;
  readonly totalAdDuration?: number;
  readonly currentAdTime?: number;
  readonly videoPlaying?: boolean;
  readonly initHidden?: boolean;
  readonly showRelatedVideos?: boolean;
}>;

const VideoSwiper = ({
  videoList,
  currentVideoIndex,
  onClick,
  isAdPlaying = false,
  title,
  titleLink,
  totalAdClips = 0,
  totalAdDuration = 0,
  currentAdTime = 0,
  videoPlaying = false,
  initHidden = false,
  showRelatedVideos = false,
  className,
}: Props) => {
  const videosWithEncoding = useMemo(() => videoList.filter((video) => video.fields.encoding), [videoList]);
  const videosCount = videosWithEncoding.length;

  const [scrollIndex, setScrollIndex] = useState(0);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const scrollRef = useScrollToCurrentSelection(scrollIndex);
  const adTime = totalAdDuration - Math.floor(currentAdTime);

  useEffect(() => {
    const container = scrollRef.current;
    if (!container) return;

    const updateScrollButtons = () => {
      if (!scrollRef.current) return;

      const container = scrollRef.current;
      const { scrollLeft, scrollWidth, clientWidth } = container;

      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth - 1);
    };

    updateScrollButtons();

    container.addEventListener('scroll', updateScrollButtons);
    window.addEventListener('resize', updateScrollButtons);

    return () => {
      container.removeEventListener('scroll', updateScrollButtons);
      window.removeEventListener('resize', updateScrollButtons);
    };
  }, [scrollRef]);

  // force scroll to active video if video gets activated in parent component, e.g. in autoplay
  useEffect(() => {
    if (currentVideoIndex) {
      setScrollIndex(currentVideoIndex);
    }
  }, [currentVideoIndex]);

  if (!videosCount) return null;

  const switchCurrentVideo = (index: number) => {
    setScrollIndex(index);
    onClick?.(index);
  };

  const scrollByPercentage = (direction: 'left' | 'right', percentage: number) => {
    if (!scrollRef.current) return;

    const container = scrollRef.current;
    const scrollAmount = container.offsetWidth * (percentage / 100);
    const scrollOptions: ScrollToOptions = {
      left: direction === 'right' ? container.scrollLeft + scrollAmount : container.scrollLeft - scrollAmount,
      behavior: 'smooth',
    };

    container.scrollTo(scrollOptions);
  };

  const scrollRight = () => scrollByPercentage('right', 25);
  const scrollLeft = () => scrollByPercentage('left', 25);

  return (
    <div
      className={clsx(styles.root, className, {
        [styles.hidden]: initHidden,
        [styles.animationOnShow]: showRelatedVideos,
      })}
      data-testid="video-swiper"
      aria-label="Playlist Swiper"
    >
      {isAdPlaying && (
        <div className={styles.thumbnailOverlay}>
          <span>{formatSecondsToMinutes(adTime)}</span>
          <span className={styles.adPlayingOverlayText}>
            {totalAdClips > 1 ? 'Gleich geht’s weiter' : 'Nur ein Spot'}
          </span>
        </div>
      )}
      <div className={clsx(styles.swiperThumbnails, isAdPlaying ? styles.disableSwiper : null)}>
        {!!title && (
          <Title as="h2" className={styles.title} link={titleLink}>
            {title}
          </Title>
        )}
        <div className={styles.swiperContainer}>
          <ul className={clsx(styles.scrollContainer)} ref={scrollRef} aria-live="polite">
            {videosWithEncoding.map((video: VideoProps, index: number) => (
              <li key={video.id} className={styles.thumbnailWrapper}>
                <VideoThumbnail
                  isActive={currentVideoIndex === index && videoPlaying}
                  title={video.fields.title}
                  kicker={video.fields.subtitle ?? ''}
                  duration={getVideoDuration(video.fields.encoding)}
                  video={video}
                  isAdPlaying={isAdPlaying}
                  onClick={() => {
                    switchCurrentVideo(index);
                  }}
                  aria-current={currentVideoIndex === index}
                />
              </li>
            ))}
          </ul>
          <NavButtons
            className={styles.navButtons}
            scrollLeft={scrollLeft}
            scrollRight={scrollRight}
            showLeft={canScrollLeft}
            showRight={canScrollRight}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoSwiper;
