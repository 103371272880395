'use client';
import { createContext, useEffect, useMemo, useState, type PropsWithChildren } from 'react';
import { useQueryParam } from 'customHooks/useQueryParam';
import type { CustomVendor, UserConsent, UserConsentContext } from 'types/consent';
import { listenForConsent } from './tcf';

export const EmptyUserConsent: UserConsent = {
  tcString: '',
  consentedPurposes: [],
  consentedVendors: [],
  grants: {},
};

export const ConsentContext = createContext<UserConsentContext>({
  consent: {
    ...EmptyUserConsent,
  },
  isSourcepointEnabled: true,
  // eslint-disable-next-line
  setConsent: () => {},
});

export type ConsentProviderProps = PropsWithChildren<NonNullable<unknown>>;

export const ConsentProvider = ({ children }: ConsentProviderProps) => {
  const [consent, setConsent] = useState<UserConsent>({ ...EmptyUserConsent });

  const isSourcepointEnabled = useQueryParam('sourcepoint') !== '0';

  useEffect(() => {
    if (!isSourcepointEnabled) {
      return;
    }
    return listenForConsent(setConsent);
  }, [isSourcepointEnabled]);

  const context = useMemo(
    () => ({
      consent,
      isSourcepointEnabled,
      setConsent,
    }),
    [consent, isSourcepointEnabled]
  );

  return <ConsentContext.Provider value={context}>{children}</ConsentContext.Provider>;
};

export const hasVendorConsent = (vendorId: string, consentedVendors?: Array<CustomVendor>): boolean => {
  if (Array.isArray(consentedVendors)) {
    return consentedVendors.some((consentedVendor) => consentedVendor._id === vendorId);
  }
  return false;
};
